<template>
  <div class="register_container">
    <div class="footer">
      <div class="copy">版权所有Copyright &copy智慧分析</div>
      <div><a style="text-decoration: none;color: white;" href="https://beian.miit.gov.cn/"
          target="_blank">已备案:粤ICP备2021054626号</a></div>
    </div>
    <div class="register_box">
      <div class="avatar_box">
       <!-- <img src="../assets/logo.png" alt>-->
      </div>
      <div class="title">欢迎使用智慧分析</div>
      <!-- 注册表单 -->
      <el-form :model="registerForm" ref="registerForm" :rules="rules" label-width="0px" class="register_form">
        <!-- 用户名 -->
        <el-form-item prop="name">
          <el-input v-model="registerForm.name" prefix-icon="el-icon-user" placeholder="请输入用户名"></el-input>
        </el-form-item>
        <!-- 手机号 -->
        <el-form-item prop="phone">
          <el-input v-model="registerForm.phone" prefix-icon="el-icon-phone" placeholder="请输入手机号码"></el-input>
        </el-form-item>
        <!-- 密码 -->
        <el-form-item prop="password">
          <el-input v-model="registerForm.password" type="password" prefix-icon="el-icon-lock" placeholder="请输入密码"
            autocomplete="off" show-password></el-input>
        </el-form-item>
        <!-- 重复密码需要校验 与第一次输入是否相同，无需提交后台 -->
        <el-form-item prop="checkPass">
          <el-input v-model="registerForm.checkPass" type="password" prefix-icon="el-icon-lock" placeholder="请再次输入密码"
            autocomplete="off" show-password></el-input>
        </el-form-item>
        <!-- 公司 -->
        <el-form-item prop="company">
          <el-select v-model="registerForm.company" placeholder="请选择公司" clearable filterable style="width:100%"
            no-data-text="无匹配公司" @change="getRole">
            <el-option v-for="item in companylist" :key="item.companyId" :label="item.companyName"
              :value="item.companyId"></el-option>
          </el-select>
        </el-form-item>
        <!-- 岗位 -->
        <el-form-item prop="role">
          <el-select v-model="registerForm.role" placeholder="请选择岗位" clearable filterable style="width:100%"
            no-data-text="请先选择公司">
            <el-option v-for="item in rolelist" :key="item.roleId" :label="item.roleName" :value="item.roleId">
            </el-option>
          </el-select>
        </el-form-item>
        <!-- 按钮 -->
        <el-form-item class="btns" style="margin-bottom:18px">
          <el-button type="primary" @click="register('registerForm')">注册</el-button>
          <el-button type="info" @click="resetForm('registerForm')">重置</el-button>
        </el-form-item>
      </el-form>

      <div class="link">
        <router-link class="login" to="/login">已有账号</router-link>
      </div>
    </div>

  </div>
</template>

<script>
  export default {
    data() {
      const validatePhone = (rule, value, callback) => {
        const reg = /^[1][3,4,5,7,8,9][0-9]{9}$/;
        if (value === "") {
          callback(new Error("请输入手机号码"));
        } else {
          if (!reg.test(value) && value !== "") {
            callback(new Error("请输入正确的手机号码"));
          } else {
            callback();
          }
        }
      };
      const validatePass = (rule, value, callback) => {
        if (value === "") {
          callback(new Error("请输入密码"));
        } else {
          if (this.registerForm.checkPass !== "") {
            this.$refs.registerForm.validateField("checkPass");
          }
          callback();
        }
      };
      const validatePass2 = (rule, value, callback) => {
        if (value === "") {
          callback(new Error("请再次输入密码"));
        } else if (value !== this.registerForm.password) {
          callback(new Error("两次输入密码不一致!"));
        } else {
          callback();
        }
      };
      const validateCompany = (rule, value, callback) => {
        if (value === "") {
          callback(new Error("请选择公司"));
        } else {
          callback();
        }
      };
      const validateRole = (rule, value, callback) => {
        if (value === "") {
          callback(new Error("请选择岗位"));
        } else {
          callback();
        }
      };
      return {
        // 数据绑定
        registerForm: {
          name: "",
          phone: "",
          password: "",
          checkPass: "",
          company: "",
          role: ""
        },
        rules: {
          phone: [{
            validator: validatePhone,
            trigger: "blur"
          }],
          password: [{
            validator: validatePass,
            trigger: "blur"
          }],
          checkPass: [{
            validator: validatePass2,
            trigger: "blur"
          }],
          company: [{
            validator: validateCompany,
            trigger: "change"
          }],
          role: [{
            validator: validateRole,
            trigger: "change"
          }]
        },
        companylist: [],
        rolelist: []
      };
    },
    mounted() {
      this.getCompany();
    },
    methods: {
      // 添加表单重置方法
      resetForm(form) {
        this.$refs[form].resetFields();
      },
      // 注册
      register(form) {
        // 验证表单内容
        this.$refs[form].validate(async valid => {
          if (!valid) {
            return;
          }
          let formData = new FormData();
          formData.append('name', this.registerForm.name)
          formData.append('phone', this.registerForm.phone)
          formData.append('password', this.$md5(this.registerForm.password))
          formData.append('companyId', this.registerForm.company)
          formData.append('roleId', this.registerForm.role)
          // for(var pair of formData.entries()) {
          //   console.log(pair[0]+ ':'+ pair[1]); // pair[0]为name ，pair[1]为value
          // }

          // 发送注册请求
          const {
            data: res
          } = await this.$http.post("user/register", formData);
          if (res.code !== 200) return this.$message.error(res.msg);
          this.$message.success(res.msg);
          // 注册成功后跳转登录
          this.$router.push("/login");
        });
      },
      async getCompany() {
        let formData = new FormData();
        formData.append('query', '') // 公司名称的查询参数
        const {
          data: res
        } = await this.$http.get("user/getCompany", formData);
        if (res.code !== 200) {
          return this.$message.error("公司列表获取失败");
        }
        this.companylist = res.data.companyList;
      },
      getRole() {
        this.$http.get("user/getCompany", {
          companyId: this.registerForm.company
        }).then(res => {
          this.rolelist = res.data.roleList;
        })
      }
    }
  };
</script>

<style lang="less" scoped>
  .footer {
    width: 100%;
    text-align: center;
    position: fixed;
    bottom: 5px;
    color: white;
  }

  .copy {
    margin-bottom: 10px;
  }

  .register_container {
    width: 100%;
    background-color: #373d41;
    height: 100%;
    // font-size:14px;
  }

  .register_box {
    width: 450px;
    height: 570px;
    background: #fff;
    border-radius: 3px;
    position: absolute;
    left: 50%;
    top: 52%;
    transform: translate(-50%, -50%);

    .title {
      width: 100%;
      height: 30px;
      position: absolute;
      text-align: center;
      top: 90px;
      font-size: 20px;
      color: #373d41;
    }

    .avatar_box {
      height: 130px;
      width: 130px;
      border: 1px solid #eee;
      border-radius: 50%;
      padding: 10px;
      box-shadow: 0 0 10px #ddd;
      position: absolute;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: #fff;

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: #eee;
      }
    }

    .link {
      width: 100%;
      position: absolute;
      bottom: 20px;
      font-size: 14px;

      a {
        color: #888;
        text-decoration: none;
      }

      a:hover {
        color: rgb(60, 150, 250);
        text-decoration: underline;
      }

      .login {
        float: right;
        margin-right: 20px;
      }
    }
  }

  .register_form {
    position: absolute;
    bottom: 0;
    width: 100%;
    // padding: 0 20px;
    padding: 0 2rem;
    box-sizing: border-box;
  }

  .btns {
    text-align: center;
  }
</style>
